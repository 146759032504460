import { ActionTypes, FORM } from "../shared/common/constant";

export const PortalConfig = {
  tableMenuOptions: [
    {
      id: 2,
      value: "View licenses",
    },
  ],

  Form: {
    id: "addCustomerForm",
    title: "Create new customers",
    fields: [
      {
        element: FORM.ELEMENTS.INPUT,
        label: "Company name",
        name: "companyName",
        type: FORM.TYPES.TEXT,
        required: true,
        defaultValue: "",
        placeholder: "Example Inc.",
        className: "",
      },
      {
        element: FORM.ELEMENTS.INPUT,
        label: "URL Company name",
        name: "urlCompanyName",
        type: FORM.TYPES.TEXT,
        required: true,
        defaultValue: "",
        placeholder: "example-inc",
        className: "",
      },
      {
        element: FORM.ELEMENTS.INPUT,
        label: "Primary contact",
        name: "primaryContactEmail",
        type: FORM.TYPES.EMAIL,
        required: true,
        defaultValue: "",
        placeholder: "email@example.com",
        className: "",
      },
      {
        element: FORM.ELEMENTS.INPUT_CHECKBOX,
        label: "Accept any domain?",
        name: "anyDomain",
        type: FORM.TYPES.CHECKBOX,
        required: false,
        defaultValue: "",
        className: "",
      },
      {
        element: FORM.ELEMENTS.INPUT_CHIP,
        label: "Email domain(s)",
        name: "domains",
        type: FORM.TYPES.URL,
        required: false,
        defaultValue: "",
        placeholder: "example.com",
        className: ""
      },
      {
        element: FORM.ELEMENTS.INPUT_CODE,
        label: "Access code",
        name: "accessCode",
        type: FORM.TYPES.TEXT,
        required: true,
        defaultValue: "",
        placeholder: "exampleCode123",
        className: "",
        validation: {
          min: 1,
          max: 30,
        },
      },
      {
        element: FORM.ELEMENTS.INPUT,
        label: "Max licenses",
        name: "maxLicenses",
        type: FORM.TYPES.NUMBER,
        required: true,
        defaultValue: "",
        placeholder: "1",
        className: "",
        validation: {
          min: 1,
          max: 99999,
        },
      },
      {
        element: FORM.ELEMENTS.INPUT,
        label: "License duration (Number of Days)",
        name: "licenseDuration",
        type: FORM.TYPES.NUMBER,
        required: true,
        defaultValue: "",
        placeholder: "1",
        className: "",
        validation: {
          min: 1,
          max: 9999,
        },
      },
      {
        element: FORM.ELEMENTS.INPUT_CHECKBOX,
        label: "Enable Auto-Update?",
        name: "autoUpdateEnabled",
        type: FORM.TYPES.CHECKBOX,
        required: false,
        defaultValue: true,
        className: "",
      },
      {
        element: FORM.ELEMENTS.INPUT_FILE,
        label: "Branding image (Max image size 1MB)",
        name: "brandingImagePlaceholder",
        type: FORM.TYPES.FILE,
        required: false,
        defaultValue: "",
        placeholder: "",
        accept: "image/*",
        onActionType: ActionTypes.selectImage,
        className:
          "border border-transparent rounded focus:outline-none focus:border-transparent",
      },
      {
        element: FORM.ELEMENTS.INV,
        name: "brandingImage",
        type: "hidden",
        required: false,
      },
      {
        element: FORM.ELEMENTS.TEXTAREA,
        label: "Branding text",
        name: "brandingText",
        type: FORM.TYPES.TEXT,
        required: false,
        defaultValue: "",
        placeholder: "Company description",
        rows: "5",
        maxLength: "1000",
        className: "",
      },
    ],
    buttons: [
      {
        type: FORM.TYPES.BUTTON,
        label: "Cancel",
        onActionType: ActionTypes.modalClose,
        className:
          "inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-gray-600 transition-all duration-200 bg-white border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-50 hover:text-gray-900",
      },
      {
				type: FORM.TYPES.SUBMIT,
				label: 'Add',
				onActionType: '',
				className: 'inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-indigo-600 border border-transparent rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 hover:bg-indigo-500'
			}
    ],
  },
};
