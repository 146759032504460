import React from "react";
import Table, {
  InlineMenu,
  SelectColumnFilter,
  StatusIndicator,
} from "../../components/Table";
import Form from "../../shared/Form";
import { Modal } from "../../shared/Modal";
import { PortalConfig } from "../../configs/portal";
import {
  ActionTypes,
  ACTION_HANDLER_TYPE,
  StatusKeys,
} from "../../shared/common/constant";
import { showAlert } from "../../shared/Alert";
import ActionHandler from "../../services/data-handler";
import { useNavigate } from "react-router-dom";
import { DateFormat } from "../../shared/Utils";
import {
  toggleBtnLoadingOff,
  toggleBtnLoadingOn,
} from "../../services/app.service";
import { base64Encode } from "@firebase/util";

const Portal = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [data, setData] = React.useState([]);
  const dataRef = React.useRef([]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Date added",
        accessor: (row) => DateFormat(row.createDateTime, true),
      },
      {
        Header: "Company name",
        accessor: "companyName",
      },
      {
        Header: "Primary contact",
        accessor: "primaryContactEmail",
      },
      {
        Header: "Domain(s)",
        accessor: "domains",
      },
      {
        Header: "Max licenses",
        accessor: "maxLicenses",
      },
      {
        Header: "Status",
        accessor: (row) => row.status.toLowerCase(),
        Filter: SelectColumnFilter,
        filter: "exact",
        Cell: StatusIndicator,
      },
      {
        id: "action-column",
        Cell: (props) => {
          const menuOptions = [...PortalConfig.tableMenuOptions];
          if (props.row.original.status === StatusKeys.ACTIVATED) {
            menuOptions.unshift({
              id: 1,
              value: "Deactivate customer",
            });
          } else {
            menuOptions.unshift({
              id: 1,
              value: "Activate customer",
            });
          }
          return InlineMenu({
            options: [...menuOptions],
            selectedItem: (option) => onMenuOptionSelect(option, props),
          });
        },
      },
    ],
    // eslint-disable-next-line
    []
  );

  React.useEffect(() => {
    getEnterprises();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    dataRef.current = [...data];
  }, [data]);

  const getEnterprises = () => {
    ActionHandler(ACTION_HANDLER_TYPE.GET_ENTERPRISES).then((result) => {
      if (result.enterprises) {
        result.enterprises.sort((a, b) => {
          return new Date(b.createDateTime) - new Date(a.createDateTime);
        });

        setData(result.enterprises);
      }
    });
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const actionHandler = async ({ type, data, event }) => {
    switch (type) {
      case ActionTypes.modalClose:
        closeModal();
        break;
      case ActionTypes.formSubmit:
        toggleBtnLoadingOn();
        if (data) {
          const body = {
            companyName: data.companyName, 
            urlCompanyName: data.urlCompanyName,
            primaryContactEmail:data.primaryContactEmail,
            anyDomain: data.anyDomain,
            domains:data.domains,
            accessCode: data.accessCode,
            maxLicenses: data.maxLicenses,
            licenseDuration: data.licenseDuration,
            autoUpdateEnabled: data.autoUpdateEnabled,
            brandingImage: data.brandingImage,
            brandingText: data.brandingText
          }
          
          const response = await ActionHandler(
            ACTION_HANDLER_TYPE.CREATE_ENTERPRISE,{body}
            
          );
          
          if (response) {
            getEnterprises();
            showAlert({
              type: ActionTypes.successAlert,
              text: "A new customer has been created.",
            });
            closeModal();
            toggleBtnLoadingOff();
          }
        } else {
          showAlert({
            type: ActionTypes.failAlert,
            text: "Customer creation failed, please try again.",
          });
          toggleBtnLoadingOff();
        }
        break;
      case ActionTypes.buttonClick:
        openModal();
        break;
      default:
        return;
    }
  };

  const handleActivation = async (rowData) => {
    let alertMessage = "";
    let endpoint = "";
    const confirmation = window.confirm(
      `Are you sure you wish to ${
        rowData.status === StatusKeys.ACTIVATED ? "deactivate" : "activate"
      } this customer?`
    );
    if (confirmation === false) {
      return;
    }
    if (rowData.status === StatusKeys.ACTIVATED) {
      alertMessage = "Deactivated enterprise.";
      endpoint = ACTION_HANDLER_TYPE.DEACTIVATE_ENTERPRISE;
    } else {
      alertMessage = "Activated enterprise.";
      endpoint = ACTION_HANDLER_TYPE.ACTIVATE_ENTERPRISE;
    }
    const response = await ActionHandler(endpoint, rowData.id);
    if (response) {
      const enterpriseIndex = dataRef.current.findIndex(
        (enterprise) => enterprise.id === response.id
      );
      if (enterpriseIndex !== -1) {
        const updatedData = [...dataRef.current];
        updatedData[enterpriseIndex] = { ...response };
        setData([...updatedData]);
        showAlert({
          type: ActionTypes.successAlert,
          text: alertMessage,
        });
      }
    }
  };

  const onMenuOptionSelect = async (payload, props) => {
    const rowData = { ...props.row.original };
    switch (payload.id) {
      case 1:
        handleActivation(rowData);
        break;
      case 2:
        navigate(
          `/enterprise/${rowData.id}/license/${base64Encode(
            rowData.companyName
          )}`
        );
        break;
      default:
        return;
    }
  };

  return (
    <div className="flex flex-col flex-1 overflow-x-hidden">
      <main>
        <div className="py-11">
          <div className="px-6 mx-auto">
            <Table
              title="Portal Users"
              columns={columns}
              data={data}
              button={PortalConfig.Form.title}
              onAction={actionHandler}
            />
          </div>
        </div>
      </main>
      <Modal isOpen={isModalOpen} onAction={actionHandler}>
        <div className="flex items-center justify-center w-full h-full">
          <div className="w-full max-w-sm">
            <div className="">
              <p className="text-xl font-bold text-gray-900">
                {PortalConfig.Form.title}
              </p>
              <Form
                className={"my-6"}
                form={PortalConfig.Form}
                onAction={actionHandler}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Portal;
