import React from 'react'
import { useTable, useGlobalFilter, useAsyncDebounce, useFilters, useSortBy, usePagination } from 'react-table'
import { classNames } from '../shared/Utils'
import { Button, PageButtonMobile, PageButton, MenuButton } from '../shared/Button'
import { SortDownIcon, SortIcon, SortUpIcon } from '../shared/Icons'
import { ActionTypes } from '../shared/common/constant'

export function SelectColumnFilter ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  const options = React.useMemo(() => {
    const unsortedOptions = new Set()
    preFilteredRows.forEach((row) => {
      unsortedOptions.add(row.values[id])
    })
    const alphabetisedOptions = Array.from(unsortedOptions).sort()
    return [...alphabetisedOptions.values()]
  }, [preFilteredRows, id])

  return (
      <div className="mx-auto">
        <div className="w-full pb-1 overflow-x-auto">
          <div className="border-b border-gray-200">
            <nav className="flex flex-col -mb-px space-x-0 md:flex-row md:space-x-10">
              <button className={classNames(
                'py-4 text-sm font-semibold transition-all duration-200 border-b-2 border-transparent hover:border-gray-300 whitespace-nowrap min-w-[100px] text-center capitalize',
                filterValue === undefined ? 'text-indigo-600 border-b-2 border-inherit border-indigo-600 hover:border-indigo-600' : null,
              )}
                 onClick={(e) => {
                   setFilter(undefined)
                 }}> All </button>
              {options.map((option, i) => (
                <button key={i} className={classNames(
                  'py-4 text-sm font-semibold transition-all duration-200 border-b-2 border-transparent hover:border-gray-300 whitespace-nowrap min-w-[100px] text-center capitalize',
                  filterValue === option ? 'text-indigo-600 border-indigo-600 border-b-2 border-inherit hover:border-indigo-600' : null,
                )}
                   onClick={(e) => {
                     setFilter(option || undefined)
                   }}> {option} </button>
              ))}
            </nav>
          </div>
        </div>
      </div>
  )
}

function GlobalFilter ({
  globalFilter,
  setGlobalFilter,
  title
}) {
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <div className="flex-1 w-full mt-3 md:pt-0 md:max-w-xs">
      <label htmlFor="" className="sr-only"> Search </label>
      <div className="relative">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 text-gray-400" fill="none" viewBox="0 0 24 24"
               stroke="currentColor" strokeWidth="2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
          </svg>
        </div>
        <input value={value || ''}
               onChange={e => {
                 setValue(e.target.value.trim())
                 onChange(e.target.value.trim())
               }}
               placeholder={`Type to search in ${title.toLowerCase()}`}
               className="border block w-full py-2 pl-10 pr-2 placeholder-gray-500 border-gray-300 rounded focus:ring-indigo-600 focus:border-indigo-600 text-sm caret-indigo-600"/>
      </div>
    </div>
  )
}

export function StatusIndicator ({ value }) {
  const status = value ? value.toLowerCase() : 'Unknown'

  return (
    <div className="inline-flex items-center capitalize">
      <svg className={classNames(
        'mr-1.5 h-2.5 w-2.5',
        status.startsWith('activated') ? 'text-green-500' : null,
        status.startsWith('active') ? 'text-green-500' : null,
        status.startsWith('confirmed') ? 'text-green-500' : null,
        status.startsWith('pending') ? 'text-orange-500' : null,
        status.startsWith('archived') ? 'text-red-500' : null,
        status.startsWith('deactivated') ? 'text-gray-300' : null,
      )}
           fill="currentColor" viewBox="0 0 8 8">
        <circle cx="4" cy="4" r="3"/>
      </svg>
      {status}
    </div>
  )
}

export function InlineMenu ({options = [], selectedItem}) {
  return (
    <MenuButton
      className="inline-flex items-center justify-center w-8 h-8 text-gray-400 transition-all duration-200 bg-white rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
      menuItems={options}
      selectOption={selectedItem}
    >
      <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
        strokeWidth="2">
        <path strokeLinecap="round" strokeLinejoin="round"
          d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z" />
      </svg>
    </MenuButton>
  )
}

function Table ({ title, columns, data, button, onAction }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } =
    useTable({
        columns,
        data,
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      usePagination,
    )

  return (
    <>
      <div className="flex flex-col md:flex-row items-center justify-between pb-8">
        <p className="text-xl font-bold text-gray-900">{title}</p>
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
          title={title}
        />
      </div>
      <div className="flex items-end md:items-center justify-between pb-8">
        {headerGroups.map((headerGroup) =>
          headerGroup.headers.map((column) =>
            column.Filter ? (
              <div key={column.id}>
                {column.render('Filter')}
              </div>
            ) : null
          )
        )}
        <div />
        {
          button &&
          <Button
            className="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-4 text-white
            transition-all duration-200 bg-indigo-600 border border-transparent rounded focus:outline-none focus:ring-2
            focus:ring-offset-2 focus:ring-indigo-600 hover:bg-indigo-500"
            onClick={(event) => onAction({ type: ActionTypes.buttonClick, event })}
          >
            {button}
          </Button>
        }
      </div>
      <div className="flex flex-col">
        <div className="bg-white overflow-x-auto">
          <div className="inline-block min-w-full py-2 pb-8 mb-2 align-middle">
            <table {...getTableProps()} border="1" className="min-w-full lg:divide-y lg:divide-gray-200">
              <thead className="lg:table-header-group">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}
                        className="py-4 pl-4 pr-2 text-left text-sm whitespace-nowrap font-medium text-gray-500">
                      <div className="flex items-center">
                        {column.render('Header')}
                        {column.id === 'action-column' ? null : (
                          <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? <SortDownIcon className="w-4 h-4 text-gray-400"/>
                              : <SortUpIcon className="w-4 h-4 text-gray-400"/>
                            : (
                              <SortIcon className="w-4 h-4 text-gray-400 opacity-100"/>
                            )}
                          </span>
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
              </thead>
              <tbody {...getTableBodyProps()} className="divide-y divide-gray-200">
              {page.map((row, i) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()} className="even:bg-gray-50 odd:bg-white">
                    {row.cells.map((cell) => {
                      return <td {...cell.getCellProps()}
                        className="px-2 py-6 text-sm font-medium text-gray-900 lg:table-cell whitespace-nowrap">
                        {
                          typeof(cell.value) === 'object' && cell?.value?.length > 0 ?
                          (
                            <div className='px-2 py-1 my-2 flex flex-wrap'>
                              {
                                cell.value.map((value, index) => {
                                return <span key={index}
                                  className="flex flex-wrap px-3 py-1 m-1 justify-between items-center text-sm font-medium font-semibold rounded cursor-pointer bg-gray-200 text-black-100">
                                  {value}
                                </span>
                                })
                              }
                            </div>
                          ) :
                          cell.render('Cell')
                        }
                      </td>
                    })}
                  </tr>
                )
              })}
              {page.length === 0 &&
                <tr>
                  <td className="px-4 py-6 text-sm font-medium text-gray-900" colSpan="2">No results found</td>
                </tr>
              }
              </tbody>
            </table>
            <div className="py-3 mt-8 mb-8 flex items-center justify-between">
              <div className="flex-1 flex justify-between mx-4 sm:hidden">
                <PageButtonMobile onClick={() => previousPage()} disabled={!canPreviousPage}>Previous</PageButtonMobile>
                <PageButtonMobile onClick={() => nextPage()} disabled={!canNextPage}>Next</PageButtonMobile>
              </div>
              <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div className="flex gap-x-2">
            <span className="text-sm text-gray-700 mt-4 ml-4">
              Page <span className="font-medium">{state.pageIndex + 1}</span> of <span
              className="font-medium">{pageOptions.length}</span>
            </span>
                  <label>
                    <span className="sr-only">Items Per Page</span>
                    <select
                      className="mt-1 block w-full rounded border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      value={state.pageSize}
                      onChange={(e) => {
                        setPageSize(Number(e.target.value))
                      }}
                    >
                      {[5, 10, 25, 50, 100].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
                <div>
                  <nav className="relative z-0 inline-flex rounded shadow-sm -space-x-px mr-4" aria-label="Pagination">
                    <PageButton
                      className="rounded-l-md"
                      onClick={() => gotoPage(0)}
                      disabled={!canPreviousPage}
                    >
                      <span className="sr-only">First</span>
                      <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                           fill="currentColor"
                           aria-hidden="true">
                        <path fillRule="evenodd"
                              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                              clipRule="evenodd"/>
                      </svg>
                    </PageButton>
                    <PageButton
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}
                    >
                      <span className="sr-only">Previous</span>
                      <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                           fill="currentColor"
                           aria-hidden="true">
                        <path fillRule="evenodd"
                              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                              clipRule="evenodd"/>
                      </svg>
                    </PageButton>
                    <PageButton
                      onClick={() => nextPage()}
                      disabled={!canNextPage
                      }>
                      <span className="sr-only">Next</span>
                      <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                           fill="currentColor"
                           aria-hidden="true">
                        <path fillRule="evenodd"
                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                              clipRule="evenodd"/>
                      </svg>
                    </PageButton>
                    <PageButton
                      className="rounded-r-md"
                      onClick={() => gotoPage(pageCount - 1)}
                      disabled={!canNextPage}
                    >
                      <span className="sr-only">Last</span>
                      <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                           fill="currentColor"
                           aria-hidden="true">
                        <path fillRule="evenodd"
                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                              clipRule="evenodd"/>
                      </svg>
                    </PageButton>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Table