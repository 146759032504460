import React from "react";
import Table, {
  InlineMenu,
  SelectColumnFilter,
  StatusIndicator,
} from "../../components/Table";
import Form from "../../shared/Form";
import { UniversalConfig } from "../../configs/universal";
import ActionHandler from "../../services/data-handler";
import { showAlert } from "../../shared/Alert";
import {
  ActionTypes,
  ACTION_HANDLER_TYPE,
  StatusKeys,
  Pattern,
} from "../../shared/common/constant";
import { DateFormat } from "../../shared/Utils";
import { Modal } from "../../shared/Modal";
import {
  toggleBtnLoadingOff,
  toggleBtnLoadingOn,
} from "../../services/app.service";
import { useNavigate } from "react-router-dom";

const Universal = () => {
  const [data, setData] = React.useState([]);
  const dataRef = React.useRef([]);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const navigate = useNavigate();

  const columns = React.useMemo(
    () => [
      {
        Header: "Date",
        accessor: (row) => DateFormat(row.createdAt, true),
      },
      {
        Header: "Name",
        accessor: (row) =>
          row.firstName && row.lastName
            ? row.firstName + " " + row.lastName
            : row.firstName,
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Key",
        accessor: "key",
      },
      {
        Header: "Auto-Update",
        accessor: "autoUpdateEnabled",
        Cell: (props) => {
          const [isModalOpen, setIsModalOpen] = React.useState(false);
          const [checked, setChecked] = React.useState(
            props.row.original.autoUpdateEnabled
          );

          return (
            <div className="flex justify-center">
              <input
                type="checkbox"
                checked={checked}
                onChange={() => {
                  setIsModalOpen(true);
                }}
                className={
                  "cursor-pointer flex gap-x-2 w-5 h-5 text-indigo-600 border-gray-300 rounded focus:ring-indigo-600"
                }
              />
              <div className="ml-3">
                <label className="text-sm font-medium text-gray-900"> </label>
              </div>
              <Modal isOpen={isModalOpen}>
                <div className="text-center my-5">
                  <p>
                    Are you sure you want to change the Auto-Update setting for{" "}
                    {props.row.original.firstName && props.row.original.lastName
                      ? props.row.original.firstName +
                        " " +
                        props.row.original.lastName
                      : props.row.original.firstName}
                    ?
                  </p>
                  <div className="mt-8 space-x-5">
                    <button
                      type="button"
                      onClick={() => {
                        setChecked(checked);
                        setIsModalOpen(false);
                      }}
                      className="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-gray-600 transition-all duration-200 bg-white border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-50 hover:text-gray-900"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setChecked(!checked);
                        modifyAutoUpdate(props);
                        setIsModalOpen(false);
                      }}
                      className="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-indigo-600 border border-transparent rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 hover:bg-indigo-500"
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </Modal>
            </div>
          );
        },
      },
      {
        Header: "Active",
        accessor: "currentActivation",
      },
      {
        Header: "Maximum",
        accessor: "maxActivation",
        Cell: (props) => {
          const [isModalOpen, setIsModalOpen] = React.useState(false);
          const [value, setValue] = React.useState(
            props.row.original.maxActivation
          );

          const handleChange = (e) => {
            if (e.target.value === "") {
              setValue(e.target.value);
            } else if (Pattern.numericRegex.test(e.target.value)) {
              setValue(e.target.value);
            }
          };

          const handleKeyDown = (e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              setIsModalOpen(true);
              setValue(value);
            }
          };

          const handleCancel = () => {
            setValue(props.row.original.maxActivation);
            setIsModalOpen(false);
          };

          const handleSubmit = (e) => {
            e.preventDefault();
            modifyMaxActivation(props, value);
            setIsModalOpen(false);
          };

          return (
            <div className="">
              <input
                type="text"
                value={value}
                minLength="1"
                maxLength="5"
                onKeyDown={handleKeyDown}
                onChange={handleChange}
                className={
                  "block w-5/6 px-2 py-1 placeholder-gray-500 border -gray-300 rounded focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm caret-indigo-600"
                }
              />
              <div className="ml-3">
                <label className="text-sm font-medium text-gray-900"> </label>
              </div>
              <Modal isOpen={isModalOpen}>
                <div className="text-center my-5">
                  <p>
                    Are you sure you want to change the Maximum Activations
                    setting for{" "}
                    {props.row.original.firstName && props.row.original.lastName
                      ? props.row.original.firstName +
                        " " +
                        props.row.original.lastName
                      : props.row.original.firstName}
                    ?
                  </p>
                  <div className="mt-8 space-x-5">
                    <button
                      type="button"
                      onClick={handleCancel}
                      className="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-gray-600 transition-all duration-200 bg-white border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-50 hover:text-gray-900"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={handleSubmit}
                      className="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-indigo-600 border border-transparent rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 hover:bg-indigo-500"
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </Modal>
            </div>
          );
        },
      },
      {
        Header: "Last Activity Date",
        accessor: (row) => (
          <div style={{ margin: "0px 0px 0px 10px" }}>
            {row.lastActivityDate ? DateFormat(row.lastActivityDate) : ""}
          </div>
        ),
      },
      {
        Header: "Status",
        accessor: (row) => row.status.toLowerCase(),
        Filter: SelectColumnFilter,
        filter: "exact",
        Cell: StatusIndicator,
      },
      {
        Header: "Expiration Date",
        accessor: (row) => (
          <div style={{ margin: "0px 0px 0px 10px" }}>
            {row.expirationDateTime ? new Date(row.expirationDateTime).toLocaleDateString("en-CA") : ""}
          </div>
        ),
      },
      {
        id: "action-column",
        Cell: (props) => {
          const menuOptions = [...UniversalConfig.tableMenuOptions];
          if (props.row.original.status === StatusKeys.ACTIVATED) {
            menuOptions.push({
              id: 3,
              value: "Deactivate key",
            });
            menuOptions.push({
              id: 4,
              value: "Archive key",
            });
          } else if (props.row.original.status === StatusKeys.DEACTIVATED) {
            menuOptions.push({
              id: 4,
              value: "Archive key",
            });
          } else if (props.row.original.status === StatusKeys.ARCHIVED) {
            menuOptions.push({
              id: 3,
              value: "Unarchive key",
            });
          }
          return InlineMenu({
            options: [...menuOptions],
            selectedItem: (option) => onMenuOptionSelect(option, props),
          });
        },
      },
    ],
    // eslint-disable-next-line
    []
  );

  React.useEffect(() => {
    getUniversalLicenses();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    dataRef.current = [...data];
  }, [data]);

  const getUniversalLicenses = () => {
    ActionHandler(ACTION_HANDLER_TYPE.GET_UNIVERSAL_LICENSES).then(
      (response) => {
        if (response.licenses) {
          response.licenses.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });

          setData(response.licenses);
        }
      }
    );
  };

  const deactivateTrial = async (rowData) => {
    rowData.id = String(rowData.id);
    rowData.status = StatusKeys.DEACTIVATED;
    const response = await ActionHandler(ACTION_HANDLER_TYPE.MODIFY_LICENSE, {
      id: Number(rowData.id),
      body: rowData,
    });
    if (response) {
      const licenseIndex = dataRef.current.findIndex(
        (license) => license.id === response.id
      );
      if (licenseIndex !== -1) {
        const updatedData = [...dataRef.current];
        updatedData[licenseIndex] = { ...response };
        setData([...updatedData]);
        showAlert({
          type: ActionTypes.successAlert,
          text: "Deactivated user license.",
        });
      }
    }
  };

  const archiveTrial = async (rowData) => {
    rowData.id = String(rowData.id);
    rowData.status = StatusKeys.ARCHIVED;
    const response = await ActionHandler(ACTION_HANDLER_TYPE.MODIFY_LICENSE, {
      id: Number(rowData.id),
      body: rowData,
    });
    if (response) {
      const licenseIndex = dataRef.current.findIndex(
        (license) => license.id === response.id
      );
      if (licenseIndex !== -1) {
        const updatedData = [...dataRef.current];
        updatedData[licenseIndex] = { ...response };
        setData([...updatedData]);
        showAlert({
          type: ActionTypes.successAlert,
          text: "Archived user license.",
        });
      }
    }
  };

  const onMenuOptionSelect = (payload, props) => {
    const rowData = { ...props.row.original };
    switch (payload.id) {
      case 1:
        const customerUrl =
          "https://dashboard.stripe.com/test/customers/" +
          rowData.customerDetailsLink;
        window.open(customerUrl, "_blank", "noopener,noreferrer");
        break;
      case 2:
        const subscriptionsUrl =
          "https://dashboard.stripe.com/test/subscriptions/" +
          rowData.subscriptionDetailsLink;
        window.open(subscriptionsUrl, "_blank", "noopener,noreferrer");
        break;
      case 3:
        const deleteConfirmation = window.confirm(
          "Are you sure you wish to deactivate this license?"
        );
        if (deleteConfirmation === false) {
          return;
        }
        deactivateTrial(rowData);
        break;
      case 4:
        const archiveConfirmation = window.confirm(
          "Are you sure you wish to archive this license?"
        );
        if (archiveConfirmation === false) {
          return;
        }
        archiveTrial(rowData);
        break;
      case 5:
        const licenseKey = rowData.key;
        navigate(`/enterprise/universal/details/` + licenseKey);
        break;
      default:
        return;
    }
  };

  const modifyAutoUpdate = async (props) => {
    const rowData = { ...props.row.original };

    rowData.id = Number(rowData.id);
    rowData.autoUpdateEnabled = !Boolean(rowData.autoUpdateEnabled);

    const response = await ActionHandler(
      ACTION_HANDLER_TYPE.MODIFY_AUTO_UPDATE,
      {
        id: Number(rowData.id),
        body: rowData,
      }
    );

    if (response) {
      const licenseIndex = dataRef.current.findIndex(
        (license) => license.id === response.id
      );
      if (licenseIndex !== -1) {
        const updatedData = [...dataRef.current];
        updatedData[licenseIndex] = { ...response };
        setData([...updatedData]);
        showAlert({
          type: ActionTypes.successAlert,
          text: "Auto-Update setting changed.",
        });
      }
    }
  };

  const modifyMaxActivation = async (props, value) => {
    const rowData = { ...props.row.original };

    rowData.id = Number(rowData.id);
    rowData.maxActivation = Number(value);

    const response = await ActionHandler(
      ACTION_HANDLER_TYPE.MODIFY_MAX_ACTIVATION,
      {
        id: Number(rowData.id),
        body: rowData,
      }
    );

    if (response) {
      const licenseIndex = dataRef.current.findIndex(
        (license) => license.id === response.id
      );
      if (licenseIndex !== -1) {
        const updatedData = [...dataRef.current];
        updatedData[licenseIndex] = { ...response };
        setData([...updatedData]);
        showAlert({
          type: ActionTypes.successAlert,
          text: "Maximum Activation setting changed.",
        });
      }
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const actionHandler = async ({ type, data, event }) => {
    switch (type) {
      case ActionTypes.modalClose:
        closeModal();
        break;
      case ActionTypes.formSubmit:
        toggleBtnLoadingOn();
        if (data) {
          // convert string to ISO date-time
          data.expirationDateTime = new Date(
            data.expirationDateTime
          ).toISOString();
          const response = await ActionHandler(
            ACTION_HANDLER_TYPE.CREATE_UNIVERSAL_USER,
            data
          );
          if (response) {
            getUniversalLicenses();
            showAlert({
              type: ActionTypes.successAlert,
              text: "A new customer has been created.",
            });
            closeModal();
            toggleBtnLoadingOff();
          }
        } else {
          showAlert({
            type: ActionTypes.failAlert,
            text: "Customer creation failed, please try again.",
          });
          toggleBtnLoadingOff();
        }
        break;
      case ActionTypes.buttonClick:
        openModal();
        break;
      default:
        return;
    }
  };

  return (
    <div className="flex flex-col flex-1 overflow-x-hidden">
      <main>
        <div className="py-11">
          <div className="px-6 mx-auto">
            <Table
              key={data.length}
              title="Universal Key Users"
              columns={columns}
              data={data}
              button={UniversalConfig.Form.title}
              onAction={actionHandler}
            />
          </div>
        </div>
      </main>
      <Modal isOpen={isModalOpen} onAction={actionHandler}>
        <div className="flex items-center justify-center w-full h-full">
          <div className="w-full max-w-sm">
            <div className="">
              <p className="text-xl font-bold text-gray-900">
                {UniversalConfig.Form.title}
              </p>
              <Form
                className={"my-6"}
                form={UniversalConfig.Form}
                onAction={actionHandler}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Universal;
