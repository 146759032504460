import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyD_s-44gheLIt5MgiGQqq6UEiiiUdEDTfY",
  authDomain: "vodium-back-office.firebaseapp.com",
  projectId: "vodium-back-office",
  storageBucket: "vodium-back-office.appspot.com",
  messagingSenderId: "499216777354",
  appId: "1:499216777354:web:40d5e29fe2d5b2fcd3ed73"
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const storage = getStorage(app);