import React, { Suspense } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Dashboard from "./pages/Dashboard";
import Trial from "./pages/customers/Trial";
import Paid from "./pages/customers/Paid";
import Trials from "./pages/Trials";
import Portal from "./pages/enterprise/Portal";
import Universal from "./pages/enterprise/Universal";
import Settings from "./pages/Settings";
import Login from "./pages/Login";
import Licenses from "./pages/enterprise/Licenses";
import PageNotFound from "./pages/404_page";
import CustomerDetails from "./pages/CustomerDetails";
import ProtectedRoute from "./services/guards/protected-route";
import { connect } from "react-redux";
import Navbar from "./components/Navbar";
import { Failure, Success } from "./shared/Alert";
import { ActionTypes } from "./shared/common/constant";
import { appInit } from "./services/app.service";

function App({ auth, alerts }) {
  const navigate = useNavigate();
  const [isAppInit, setIsAppInit] = React.useState(false);

  React.useEffect(() => {
    appInit().then((appInit) => {
      setIsAppInit(true);
    });
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (isAppInit) {
      if (!auth.isAuthenticated) {
        navigate("/login");
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  return (
    <Suspense>
      <div className="bg-gray-50 min-h-screen">
        <div className="flex flex-col absolute top-4 right-4 z-50">
          {alerts.map((al, alertIndex) => {
            return (
              <React.Fragment key={alertIndex}>
                {al.type === ActionTypes.successAlert && (
                  <Success index={alertIndex}>{al.text}</Success>
                )}
                {al.type === ActionTypes.failAlert && (
                  <Failure index={alertIndex}>{al.text}</Failure>
                )}
              </React.Fragment>
            );
          })}
        </div>
        <Routes>
          {/* Logout */}
          <Route path="/" element={<Login />} />
          <Route path="login" element={<Login />} />

          {/* Dashboard */}
          <Route
            path="/*"
            element={
              <ProtectedRoute
                isLoggedIn={auth.isAuthenticated}
                isAppInit={isAppInit}
              >
                <div>
                  <Sidebar />
                  <div className="flex flex-col flex-1 overflow-x-hidden xl:pl-72">
                    <Navbar />
                    <main>
                      <div>
                        <Routes>
                          <Route path="dashboard" element={<Dashboard />} />

                          {/* B2C */}
                          <Route path="customers">
                            <Route path="paid" element={<Paid />} />
                            <Route
                              path="paid/details/:key"
                              element={<CustomerDetails />}
                            />
                            <Route path="trials" element={<Trial />} />
                          </Route>

                          {/* Trials */}
                          <Route path="trials" element={<Trials />} />
                            <Route
                              path="trials/details/:key"
                              element={<CustomerDetails />}
                            />

                          {/* Enterprise */}
                          <Route path="enterprise">
                            <Route path="portal" element={<Portal />} />
                            <Route path="universal" element={<Universal />} />
                            <Route
                              path="universal/details/:key"
                              element={<CustomerDetails />}
                            />
                            <Route
                              path=":id/license/:name"
                              element={<Licenses />}
                            />
                          </Route>
                          {/* Settings */}
                          <Route path="settings" element={<Settings />} />
                          {/* 404 Page */}
                          <Route path="*" element={<PageNotFound />} />
                        </Routes>
                      </div>
                    </main>
                  </div>
                </div>
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </Suspense>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    alerts: state.app.alerts,
  };
};

export default connect(mapStateToProps)(App);
