import { showAlert } from "../shared/Alert";
import { ActionTypes, ACTION_HANDLER_TYPE } from "../shared/common/constant";
import { toggleBtnLoadingOff } from "./app.service";
import DashboardService from "./dashboard.service";
import EnterpriseService from "./enterprise.service";
import LicenseService from "./license.service";
import TrialService from "./trial.service";
import DetailService from "./details.service";
import FirebaseService from "./firebase.service";

const ActionHandler = (type, payload = null) => {
  return new Promise(async (resolve, reject) => {
    try {
      switch (type) {
        case ACTION_HANDLER_TYPE.GET_TRIALS:
          resolve(await TrialService.getTrials());
          break;
        case ACTION_HANDLER_TYPE.GET_METRICS:
          resolve(await DashboardService.getMetrics());
          break;
        case ACTION_HANDLER_TYPE.GET_LICENSES:
          resolve(await LicenseService.getLicenses());
          break;
        case ACTION_HANDLER_TYPE.GET_TRIAL_LICENSES:
          resolve(await LicenseService.getTrialLicenses());
          break;
        case ACTION_HANDLER_TYPE.GET_UNIVERSAL_LICENSES:
          resolve(await LicenseService.getUniversalLicenses());
          break;
        case ACTION_HANDLER_TYPE.GET_LICENSE_DETAILS:
          resolve(await DetailService.getLicenseDetails(payload.key));
          break;
        case ACTION_HANDLER_TYPE.GET_ACTIVITY_DETAILS:
          resolve(await DetailService.getActivityDetails(payload.key));
          break;
        case ACTION_HANDLER_TYPE.GET_ENTERPRISES:
          resolve(await EnterpriseService.getEnterprises());
          break;
        case ACTION_HANDLER_TYPE.GET_ENTERPRISES_LICENSES:
          resolve(EnterpriseService.getEnterpriseLicenses(payload));
          break;
        case ACTION_HANDLER_TYPE.GET_ENTERPRISES_DETAILS:
          resolve(EnterpriseService.getEnterpriseDetails(payload.id));
          break;
        case ACTION_HANDLER_TYPE.CREATE_ENTERPRISE:
          resolve(await EnterpriseService.createEnterprises(payload.body));
          break;
        case ACTION_HANDLER_TYPE.CREATE_LICENSE_BATCH:
          resolve(await LicenseService.createLicenseBatch(payload));
          break;
        case ACTION_HANDLER_TYPE.CREATE_UNIVERSAL_USER:
          resolve(await EnterpriseService.createUniversalKeyUser(payload));
          break;

        case ACTION_HANDLER_TYPE.MODIFY_LICENSE:
          resolve(await LicenseService.modifyLicense(payload.id, payload.body));
          break;
        case ACTION_HANDLER_TYPE.ACTIVATE_ENTERPRISE:
          resolve(await EnterpriseService.activateEnterprises(payload));
          break;
        case ACTION_HANDLER_TYPE.DEACTIVATE_ENTERPRISE:
          resolve(await EnterpriseService.deactivateEnterprises(payload));
          break;
        case ACTION_HANDLER_TYPE.DELETE_LICENSE:
          resolve(
            await EnterpriseService.deleteEnterpriseLicense(
              payload.id,
              payload.licenseId
            )
          );
          break;
        case ACTION_HANDLER_TYPE.MODIFY_AUTO_UPDATE:
          resolve(await LicenseService.modifyAutoUpdate(payload.body));
          break;
        case ACTION_HANDLER_TYPE.MODIFY_MAX_ACTIVATION:
          resolve(await LicenseService.modifyMaxActivation(payload.body));
          break;
        case ACTION_HANDLER_TYPE.MODIFY_EXPIRY_DATE_TIME:
          resolve(await LicenseService.modifyExpirationDateTime(payload.body));
          break;
        case ACTION_HANDLER_TYPE.MODIFY_ENTERPRISE:
          resolve(await EnterpriseService.modifyEnterprise(payload.body));
          break;
        case ACTION_HANDLER_TYPE.MODIFY_ENTERPRISE_EXPIRATION:
          resolve(
            await EnterpriseService.modifyEnterpriseExpiry(
              payload.id,
              payload.body
            )
          );
          break;
        case ACTION_HANDLER_TYPE.SET_CUSTOM_CLAIMS:
          resolve(await FirebaseService.setCustomClaims(payload.body));
          break;
        default:
          return Promise.reject("Action Type not found");
      }
    } catch (err) {
      if (err.message) {
        const errorMessage = String(err.message).toLowerCase();
        if (errorMessage.includes("unexpected end of json input")) {
          showAlert({
            type: ActionTypes.failAlert,
            text: "Due to inactivity, you must refresh the page.",
          });
        } else {
          showAlert({
            type: ActionTypes.failAlert,
            text: "Operation failed. " + err.message,
          });
        }
      }
      toggleBtnLoadingOff();
      return Promise.reject(err);
    }
  });
};

export default ActionHandler;
