import { toggleSidebar } from "../services/app.service"
import logo from '../assets/vo-logo.svg';

const Navbar = () => {
  return (
    <div className="sticky top-0 z-10 flex flex-shrink-0 h-16 bg-white border-b border-gray-200 xl:hidden">
        <div className="flex flex-1 px-4 sm:px-6 md:px-8">
          <div className="flex items-center flex-1">
            <div className="flex items-center -m-2 xl:hidden">
              <button type="button"
                onClick={toggleSidebar}
                className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600">
                <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                  stroke="currentColor" strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16"></path>
                </svg>
              </button>
            </div>

            <div className="flex ml-6 xl:ml-0">
              <div className="flex items-center flex-shrink-0">
                <img className="block w-auto h-8 xl:hidden" src={logo} alt=""/>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}


export default Navbar
